import { Popover, PopoverButton, PopoverPanel, Transition } from '@headlessui/react';
import { Fragment, useRef, useState } from 'react';

const DTooltip = ({ children, content, position = 'top center' }) => {
  const buttonRef = useRef(null);
  const [isHovered, setIsHovered] = useState(false);


  const handleMouseEnter = () => setIsHovered(true);
  const handleMouseLeave = () => setIsHovered(false);

  return (
    <Popover className="relative inline-flex items-center">
      {({ open }) => (
        <>
          <PopoverButton
            ref={buttonRef}
            onMouseEnter={() => {
              buttonRef.current?.click();
              handleMouseEnter();
            }}
            onMouseLeave={() => {
              buttonRef.current?.click();
              handleMouseLeave();
            }}
          >
            {children}
          </PopoverButton>

          <Transition
            show={open}
            as={Fragment}
            enter="transition-opacity duration-300 ease-in-out"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity duration-300 ease-in-out"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <PopoverPanel
              anchor={position}
              className="bg-gray-800 text-white text-xs rounded px-2 py-1 whitespace-nowrap shadow-sm !max-w-[400px] text-wrap leading-normal"
              onMouseEnter={handleMouseEnter}
              onMouseLeave={handleMouseLeave}
            >
              <span className="leading-normal">{content}</span>
            </PopoverPanel>
          </Transition>
        </>
      )}
    </Popover>
  );
};

export default DTooltip;
