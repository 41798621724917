import { Suspense } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { DateTime } from 'luxon';
import { useUserStore } from '@/stores/user/userStore';
import useLogout from '@/application/auth/logout';

const PublicRoute = ({ permission, children }) => {
  const location = useLocation();
  const logout = useLogout();

  const { auth } = useUserStore();

  const dateCreated = DateTime.fromISO(auth.date_created, { zone: 'utc' });
  const targetDate = DateTime.fromISO('2024-12-20', { zone: 'utc' }).set({
    hour: 0,
    minute: 0,
    second: 0,
    millisecond: 0,
  });

  if (
    dateCreated < targetDate &&
    !window.location.hostname.includes('localhost')
  ) {
    window.location.href =
      import.meta.env.VITE_APP_BASE_URL_OLD_URL +
      'log-in?token=' +
      auth.access_token;
    logout();
    return;
  }

  return auth.access_token && !location.pathname.includes('sign-up') ? (
    <Navigate to="/" replace />
  ) : (
    <Suspense>{children}</Suspense>
  );
};

export default PublicRoute;
