import React, { Suspense, useEffect } from 'react';
import { Outlet } from 'react-router-dom';

import useLayoutStore from '@/stores/layout/layoutStore';

import LayoutShell from '@/layouts/LayoutShell';
import DLoading from '@/components/DLoading';

const Entrypoint = () => {
  const layoutTitle = useLayoutStore((state) => state.layoutTitle);
  const progressBar = useLayoutStore((state) => state.progressBar);
  const setIsDarkMode = useLayoutStore((state) => state.setIsDarkMode);

  useEffect(() => {
    const theme = localStorage.getItem('theme');

    if (theme === 'dark') {
      document.documentElement.classList.add('dark');
      setIsDarkMode(true);
    }
  }, []);

  return (
    <LayoutShell title={layoutTitle} progressBar={progressBar}>
      <Suspense fallback={<DLoading show={true} />}>
        <Outlet />
      </Suspense>
    </LayoutShell>
  );
};

export default Entrypoint;
