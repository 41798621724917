import React from 'react';

import clsx from 'clsx';

import { Textarea } from '@headlessui/react';
import DInputBlock from './DInputBlock';

const DTextArea = React.forwardRef(
  (
    {
      children,
      label,
      resize = false,
      error,
      type,
      className,
      required,
      minRows,
      ...props
    },
    ref
  ) => {
    return (
      <DInputBlock label={label} error={error} required={required}>
        <div className="flex flex-col gap-size1 w-full">
          <div
            className={clsx(
              'w-full flex  items-center rounded-size1 border-[1px] focus-within:border-black/20 border-black/5 py-size1 bg-[--dt-color-surface-100]  px-size2 gap-size1',
              error && 'border-negative-100'
            )}
          >
            <Textarea
              ref={ref}
              data-testid={`d-textarea-${props.name ?? props.id ?? ''}`}
              rows={minRows}
              {...props}
              type={type}
              className={clsx(
                'w-full resize-none placeholder-black/10 bg-transparent text-sm data-[focus]:border-black/20',
                resize && 'resize-y'
              )}
            />
          </div>
        </div>
      </DInputBlock>
    );
  }
);

DTextArea.displayName = 'DTextArea';

export default DTextArea;
