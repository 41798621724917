import { create } from 'zustand';

const initialChatbotData = {
  chatbotName: '',
  chatbotUrls: [{ url: 'https://', sweepEntireDomain: true, max_urls: 1 }],
  chatbotExcludedUrls: [{ url: 'https://' }],
  chatbotFiles: [],
  chatbotBulkUploadedUrls: [],
  personalities_template: null,
  allowAccessToInternet: false,
  temperature: 30
}

export const useCreateChatbotStore = create((set) => ({
  chatbotData: initialChatbotData,
  setChatbotData: (key, value) =>
    set((state) => ({ chatbotData: { ...state.chatbotData, [key]: value } })),
  resetChatbotData: () => set({ chatbotData: { ...initialChatbotData, chatbotUrls: [{ url: 'https://', sweepEntireDomain: true, max_urls: 1 }] } }),
}));
