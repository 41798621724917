import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/react';

import DNotificationBlock from '../DNotificationBlock';
import NotificationIcon from '../Global/Icons/NotificationIcon';
import useNotificationsPolling from '@/hooks/useNotificationsPolling';
import useNotificationsStore from '@/stores/notifications/notifications';

const DNotificationHeader = () => {
  useNotificationsPolling(30000);
  const updates = useNotificationsStore((state) => state.notifications.updates);
  return (
    <Menu>
      {({ open }) => (
        <>
          {open && (
            <div className="transition-all fixed inset-0 bg-white/90 md:hidden z-10"></div>
          )}
          {open && (
            <div className="transition-all fixed inset-0 top-20 bg-white/90 md:hidden z-10"></div>
          )}
          <MenuButton className="inline-flex items-center justify-center gap-2 rounded-size0 font-semibold text-black  focus:outline-none data-[hover]:bg-grey-2 data-[open]:bg-grey-5 data-[focus]:outline-1 data-[focus]:outline-black size-[40px] inset-1">
            <div className="relative">
              <NotificationIcon  className="size-5"/>
              {updates.filter((update) => !update.seen).length > 0 && (
                <div className="absolute top-[-10px] right-[-10px] size-size3 bg-negative-200 rounded-full text-xs flex items-center justify-center text-white leading-none">
                  {updates.filter((update) => !update.seen).length}
                </div>
              )}
            </div>
          </MenuButton>

          <MenuItems
            transition
            anchor="bottom end"
            className="w-full md:w-72 z-20 origin-top-right p-size1 rounded-size0 border border-black/5 bg-white text-black transition duration-100 ease-out [--anchor-gap:var(--spacing-1)] focus:outline-none data-[closed]:scale-95 data-[closed]:opacity-0"
          >
            <DNotificationBlock />
          </MenuItems>
        </>
      )}
    </Menu>
  );
};

export default DNotificationHeader;
