import { Link } from 'react-router-dom';

import useLogout from '@/application/auth/logout';
import { useUserStore } from '@/stores/user/userStore';
import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/react';

import UserAvatar from '../../assets/avatar/user.png';
import DDarkModeSelector from '../DDarkModeSelector';
import DUserAvatar from '../Global/DUserAvatar';
import ColorIcon from '../Global/Icons/ColorIcon';
import LogoutIcon from '../Global/Icons/LogoutIcon';
import MembershipIcon from '../Global/Icons/MembershipIcon';
import UserIcon from '../Global/Icons/UserIcon';

const HeaderAccountMenu = () => {
  const logout = useLogout();
  const user = useUserStore((state) => state.user);

  return (
    <div className="text-right h-full flex flex-col justify-center items-center">
      <Menu>
        <MenuButton className="inline-flex items-center justify-center gap-2 rounded-full font-semibold text-black  focus:outline-none  data-[focus]:outline-1 data-[focus]:outline-black size-[40px]">
          <DUserAvatar size="lg" image={user?.profile_image || UserAvatar} />
        </MenuButton>

        <MenuItems
          transition
          anchor="bottom end"
          className="w-52 origin-top-right rounded-xl border border-black/5 bg-white p-1  text-black transition duration-100 ease-out [--anchor-gap:var(--spacing-1)] focus:outline-none data-[closed]:scale-95 data-[closed]:opacity-0 z-20"
        >
          <MenuItem>
            <div className="p-size1">
              <p className="text-base">{user?.full_name}</p>
              <p className="text-xs text-grey-50">{user?.email}</p>
            </div>
          </MenuItem>
          <div className="my-1 h-px bg-grey-5" />
          <MenuItem>
            <Link
              className="flex text-black items-center gap-size1 py-size2 px-size1 text-base font-regular tracking-tight hover:bg-grey-5 hover:text-black rounded-size0"
              to="/profile"
            >
              <UserIcon />
              <span>Profile</span>
            </Link>
          </MenuItem>
          <MenuItem>
            <Link
              className="flex text-black items-center gap-size1 py-size2 px-size1 text-base font-regular tracking-tight hover:bg-grey-5 hover:text-black rounded-size0"
              to="/membership"
            >
              <MembershipIcon />
              <span>Membership</span>
            </Link>
          </MenuItem>
          <MenuItem>
            <Link
              className="flex text-black items-center gap-size1 py-size2 px-size1 text-base font-regular tracking-tight hover:bg-grey-5 hover:text-black rounded-size0"
              to="/branding"
            >
              <ColorIcon />
              <span>Branding</span>
            </Link>
          </MenuItem>
          <MenuItem>
            <DDarkModeSelector />
          </MenuItem>
          <MenuItem>
            <button
              className="flex items-center gap-size1 py-size2 px-size1 text-base font-regular tracking-tight hover:bg-grey-5 hover:text-black rounded-size0 w-full "
              onClick={() => logout()}
            >
              <LogoutIcon />
              <span>Logout</span>
            </button>
          </MenuItem>
        </MenuItems>
      </Menu>
    </div>
  );
};
export default HeaderAccountMenu;
