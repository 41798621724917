import generateApiEndpoint from '@/helpers/generateApiEndpoint';
import { DEFAULT_HEADERS } from './constants.service';
import http from './http'

export const applyForBetaAccess = (reason) => {
  return http.post(generateApiEndpoint('user/avatar_apply'), {}, {
    params: {
      reason
    },
    headers: DEFAULT_HEADERS
  });
};
