import http from './http';

export const getTeams = () => {
  return http.get(import.meta.env.VITE_APP_BASE_API + 'teams', {
    headers: {
      'Content-Type': 'application/json'
    }
  });
};

export const createTeam = (name) => {
  return http.post(
    import.meta.env.VITE_APP_BASE_API + 'teams',
    {
      name: name
    },
    {
      headers: {
        'Content-Type': 'application/json'
      }
    }
  );
};

export const getTeam = (id) => {
  return http.get(import.meta.env.VITE_APP_BASE_API + 'teams/' + id, {
    headers: {
      'Content-Type': 'application/json'
    }
  });
};

export const updateTeam = (id, name) => {
  return http.patch(import.meta.env.VITE_APP_BASE_API + 'teams/' + id, { name }, {
    headers: {
      'Content-Type': 'application/json'
    }
  });
};

export const getTeamMembers = (id) => {
  return http.get(import.meta.env.VITE_APP_BASE_API + 'teams/' + id + '/members', {
    headers: {
      'Content-Type': 'application/json'
    }
  });
};

export const createTeamMember = (id, member) => {
  return http.post(
    import.meta.env.VITE_APP_BASE_API + 'teams/' + id + '/members',
    {
      ...member
    },
    {
      headers: {
        'Content-Type': 'application/json'
      }
    }
  );
};

export const editTeamMember = (id, memberId, member) => {
  return http.patch(
    import.meta.env.VITE_APP_BASE_API + 'teams/' + id + '/members/' + memberId,
    {
      ...member
    },
    {
      headers: {
        'Content-Type': 'application/json'
      }
    }
  );
};

export const deleteTeamMember = (id, memberId) => {
  return http.delete(
    import.meta.env.VITE_APP_BASE_API + 'teams/' + id + '/members/' + memberId,
    {},
    {
      params: {},
      headers: {
        'Content-Type': 'application/json'
      }
    }
  );
};

export const getTeamRoles = (id) => {
  return http.get(import.meta.env.VITE_APP_BASE_API + 'team-roles', {
    params: {
      team_id: id
    },
    headers: {
      'Content-Type': 'application/json'
    }
  });
};

export const createTeamRole = (role) => {
  return http.post(
    import.meta.env.VITE_APP_BASE_API + 'team-roles',
    {
      ...role
    },
    {
      headers: {
        'Content-Type': 'application/json'
      }
    }
  );
};

export const editTeamRole = (id, team_role_id, permissions) => {
  return http.patch(
    import.meta.env.VITE_APP_BASE_API + 'team-roles/' + team_role_id,
    {
      ...permissions
    },
    {
      params: {
        team_id: id
      },
      headers: {
        'Content-Type': 'application/json'
      }
    }
  );
};

export const deleteTeamRole = (id) => {
  return http.delete(import.meta.env.VITE_APP_BASE_API + 'team-roles/' + id, {
    headers: {
      'Content-Type': 'application/json'
    }
  });
};

export const getRolePermissions = () => {
  return http.get(import.meta.env.VITE_APP_BASE_API + 'team-roles/all-permissions', {
    headers: {
      'Content-Type': 'application/json'
    }
  });
};


export const getDefaultImages = () => {
  return http.get(import.meta.env.VITE_APP_BASE_API + 'teams/default-images', {
    headers: {
      'Content-Type': 'application/json'
    }
  });
};


export const createTeamOnboarding = (data) =>{
  return http.post(import.meta.env.VITE_APP_BASE_API + 'teams/on-boarding', data, {
    headers: {
      'Content-Type': 'application/json'
    }
  });
}

export const leaveTeam = (id) => {
  return http.delete(import.meta.env.VITE_APP_BASE_API + 'teams/' + id + '/leave-team', {
    headers: {
      'Content-Type': 'application/json'
    }
  });
}

export const resendInvitation = (id, memberId) => {
  return http.post(import.meta.env.VITE_APP_BASE_API + 'teams/' + id + '/members/resend', {}, {
    params: {
      team_user_id: memberId
    },
    headers: {
      'Content-Type': 'application/json'
    }
  });
}

export const acceptTeamManagementInvitation = (teamId, token) => {
  return http.post(
    import.meta.env.VITE_APP_BASE_API + 'teams/' + teamId + '/members/accept',
    {},
    {
      params: {
        token: token
      },
      headers: {
        'Content-Type': 'application/json'
      }
    }
  );
};
