import generateApiEndpoint from '@/helpers/generateApiEndpoint';
import { DEFAULT_HEADERS } from './constants.service';
import http from './http';

/**
 * Logs in the user using email and password.
 *
 * @param {Object} data - The login credentials.
 * @param {string} data.email - The user's email address.
 * @param {string} data.password - The user's password.
 * @returns {Promise} The HTTP response from the login request.
 */
export const login = async (data) => {
  return http.post(`${import.meta.env.VITE_APP_BASE_API}login`, data, {
    headers: {
      'Content-Type': 'application/json'
    }
  });
};

/**
 * Logs in the user using Google OAuth.
 *
 * @param {Object} params - The Google login parameters.
 * @param {string} params.access_token - The Google access token.
 * @param {string} [params.rewardful_referral] - Optional referral token from Rewardful.
 * @param {boolean} [params.contact_consent] - User's consent for contact.
 * @param {string} [params.kb_id] - The knowledge base ID.
 * @param {boolean} [params.isLanding] - Whether the login originated from the landing page.
 * @returns {Promise} The HTTP response from the Google login request.
 */
export const loginWithGoogle = ({
  access_token,
  rewardful_referral,
  contact_consent,
  kb_id,
  isLanding
}) => {
  return http.get(`${import.meta.env.VITE_APP_BASE_API}sso/google/login`, {
    params: {
      access_token,
      rewardful_referral,
      contact_consent,
      kb_id,
      is_landing_page: isLanding
    },
    headers: {
      'Content-Type': 'application/json'
    }
  });
};

/**
 * Logs in the user using Google OAuth.
 *
 * @param {Object} params - The Google login parameters.
 * @param {string} params.access_token - The Google access token.
 * @param {string} [params.rewardful_referral] - Optional referral token from Rewardful.
 * @param {boolean} [params.contact_consent] - User's consent for contact.
 * @param {string} [params.kb_id] - The knowledge base ID.
 * @param {boolean} [params.isLanding] - Whether the login originated from the landing page.
 * @returns {Promise} The HTTP response from the Google login request.
 */
export const signupWithGoogle = ({
  access_token,
  rewardful_referral,
  contact_consent,
  kb_id,
  isLanding
}) => {
  return http.get(generateApiEndpoint('sso/google/signup'), {
    params: {
      access_token,
      rewardful_referral,
      contact_consent,
      kb_id,
      is_landing_page: isLanding
    },
    headers: DEFAULT_HEADERS
  });
};

/**
 * Logs out the user.
 *
 * @param {Object} data - The logout request data.
 * @returns {Promise} The HTTP response from the logout request.
 */
export const logout = async (data) => {
  return http.post(`${import.meta.env.VITE_APP_BASE_API}logout`, data, {
    headers: {
      'Content-Type': 'application/json'
    }
  });
};

/**
 * Refreshes the user's authentication token.
 *
 * @param {Object} data - The refresh token request data.
 * @returns {Promise} The HTTP response from the refresh token request.
 */
export const refreshToken = (data) => {
  return http.post(`${import.meta.env.VITE_APP_BASE_API}login/refresh`, data, {
    headers: {
      'Content-Type': 'application/json'
    }
  });
};

/**
 * Sends a request to initiate the password recovery process.
 *
 * @param {Object} data - The request data containing the email for password recovery.
 * @param {string} data.email - The user's email address.
 * @returns {Promise} The HTTP response from the forgot password request.
 */
export const forgotPassword = (data) => {
  return http.post(`${import.meta.env.VITE_APP_BASE_API}auth/forgot-password`, data, {
    headers: {
      'Content-Type': 'application/json'
    }
  });
};

/**
 * Sends a request to reset the user's password.
 *
 * @param {Object} data - The request data for resetting the password.
 * @param {string} data.token - The reset password token.
 * @param {string} data.password - The new password.
 * @returns {Promise} The HTTP response from the reset password request.
 */
export const resetPassword = (data) => {
  return http.post(`${import.meta.env.VITE_APP_BASE_API}auth/reset-password`, data, {
    headers: {
      'Content-Type': 'application/json'
    }
  });
};


export const register = (data) => {
  return http.post(`${import.meta.env.VITE_APP_BASE_API}auth/register`, data, {
    headers: {
      'Content-Type': 'application/json'
    }
  });
};

export const saveUserTier = (data) => {
  return http.post(`${import.meta.env.VITE_APP_BASE_API}sso/google/select-trial`, data, {
    headers: {
      'Content-Type': 'application/json'
    }
  });
}