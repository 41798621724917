import React from 'react';

import { Description, Field, Label } from '@headlessui/react';

import DAlert from '../DAlert';
import DTransition from '../DTransition';
import InfoIcon from '../Icons/InfoIcon';
import DTooltip from '../DTooltip';
import clsx from 'clsx';
import DSpinner from '../DSpinner';

const DInputBlock = React.forwardRef(
  (
    {
      children,
      error,
      alert,
      name,
      label,
      description,
      className = '',
      note,
      hiddenLabel = false,
      labelClassName = '',
      required = false,
      tooltipContent,
      size = 'md',
      shouldGrow = true,
      hideError = false,
      loader = false,
      ...props
    },
    ref
  ) => {
    return (
      <div
        className={clsx('relative', className, {
          'w-full grow': shouldGrow,
          'grow-0': !shouldGrow,
        })}
      >
        <Field className="flex flex-col gap-size1" ref={ref}>
          {!hiddenLabel && (
            <Label
              className={clsx(
                'text-base font-medium tracking-tight flex items-center gap-size0',
                labelClassName,
                {
                  '!text-xs': size === 'sm',
                }
              )}
            >
              <div className="flex items-center gap-size1">
                <span className="w-max">
                  {label} {required && <span className="text-error">*</span>}
                </span>
                {loader && <DSpinner />}
              </div>
              {tooltipContent && (
                <DTooltip content={tooltipContent}>
                  <InfoIcon
                    className={clsx({
                      '!size-size3': size === 'sm',
                    })}
                  />
                </DTooltip>
              )}
            </Label>
          )}
          {description && (
            <Description className="text-xs text-grey-50">
              {description}
            </Description>
          )}

          {children}
          {note && (
            <span className="text-xs tracking-tight text-grey-20">{note}</span>
          )}

          {!hideError && (
            <div className="relative h-0">
              <span
                className="transition-all duration-300 absolute text-error h-4"
                dangerouslySetInnerHTML={{ __html: error }}
              />
            </div>
          )}
        </Field>
      </div>
    );
  }
);

DInputBlock.displayName = 'DInputBlock';

export default DInputBlock;
